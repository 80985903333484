<template>
    <el-col class="page">
        <el-col class="title">我的资源</el-col>
        <el-col class="pane-container">
            <el-col :class="{'pane-active':active==3}" class="cursor-item pane pane-first" @click.native="paneActive('3')">全部</el-col>
            <el-col :class="{'pane-active':active==0}" class="cursor-item pane pane-second" @click.native="paneActive('0')">有效资源</el-col>
            <el-col :class="{'pane-active':active==1}" class="cursor-item pane pane-third" @click.native="paneActive('1')">即将过期资源</el-col>
            <el-col :class="{'pane-active':active==2}" class="cursor-item pane pane-fourth" @click.native="paneActive('2')">已过期资源</el-col>
            <el-col style="float: right;width: 700px">
                <el-col class="pane-select">
                    商品类型:
                    <el-select v-model="value" placeholder="请选择商品类型" type="danger" class="select_time">
                        <el-option
                                v-for="(item,idx) in options"
                                :key="idx"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>

                </el-col>
                <el-col class="pane-search">
                    商品名称:
                    <el-input
                            placeholder="请输入商品名称"
                            v-model="productInput"
                            class="select_time">
                    </el-input>
                    <el-button class="query-btn" @click.native="queryResource()">查询</el-button>
                </el-col>
            </el-col>

        </el-col>
        <el-col class="table-container">
            <el-table
                    :data="tableData"
                    border
                    style="width: 100%"
                    v-loading="loading"
            >
                <el-table-column prop="orderNo" label="订单编号"></el-table-column>
                <el-table-column prop="name" label="商品名称"></el-table-column>
                <el-table-column label="商品类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == 1">
                            云市场
                        </span>
                        <span v-else-if="scope.row.type==2">
                            云资源
                        </span>
                        <span v-else>
                            解决方案
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="商品数量" class-name="table-col" ></el-table-column>
                <el-table-column prop="price" label="商品价格" class-name="table-col"></el-table-column>
                <el-table-column label="过期状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isTimeOut == 0">
                            未过期
                        </span>
                        <span v-else-if="scope.row.isTimeOut==1">
                            即将过期
                        </span>
                        <span v-else style="color: #F76F66">
                            已过期
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="duration" label="过期时间"></el-table-column>
                <el-table-column label="操作" class-name="table-col" >
                    <template slot-scope="scope" >
                        <span class="cursor-item" v-if="scope.row.isTimeOut == 0"  @click="toCloudMarket(scope.row)" style="color: #F76F66">
                            延长时间
                        </span>
                        <span class="cursor-item" v-else-if="scope.row.isTimeOut == 1" @click="toCloudMarket(scope.row)" style="color: #F76F66">
                           立即续费
                        </span>
                        <span class="cursor-item" v-else style="color: #F76F66" @click="toCloudMarket(scope.row)">
                           重新购买
                        </span>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
        <el-col style="margin-top: 20px">
            <Page :total="page.total" show-total show-elevator style="font-size: 14px"
             :current="page.pageNo" :page-size="page.pageSize" @on-page-size-change="onPageSizeChange" @on-change="changePage" ></Page>
        </el-col>
    </el-col>
</template>

<script>
    import iView from 'iview'    //引入ivew库
    import 'iview/dist/styles/iview.css'    // 使用 CSS
    import Vue from 'vue'
    import resourceApi from  '../../../api/buyer/myResource/resourceApi.js'

    Vue.use(iView)
    export default {
        name: "resources",
        data() {
            return {
              loading: true,
                productInput: '',
                page: {
                pageNo: 1,
                pageSize: 10,
                total: 0
                },
                active: 3,
                options: [{
                    value: 0,
                    label: '全部'
                }, {
                    value: 1,
                    label: '云市场'
                }, {
                    value: 2,
                    label: '云资源'
                }, {
                    value: 3,
                    label: '解决方案'
                }],
                value: 0,
                tableData: []
            }
        },
        methods: {
            paneActive(id) {
                this.active = id;
                this.getResourceList()
            },
            getResourceList() {
                resourceApi.getResourceList({pageNo:this.page.pageNo,pageSize:this.page.pageSize,isTimeOut:this.active==3?'':this.active,type: this.value,name:this.productInput}).then((res) => {
                  this.timer = setTimeout(()=>{   //设置延迟执行
                    this.loading = false
                  },300)
                    if(res.data == null){
                        this.tableData = []
                        this.page.total = 0
                    }
                    this.page.total = res.data.total
                    this.tableData = res.data.records
                })
            },
            onPageSizeChange(index) {
                this.page.pageSize = index;
                this.getResourceList();
             },
            changePage(index) {
                this.page.pageNo = index;
                this.getResourceList();
            },
            queryResource(){
                this.getResourceList()
            },
          // 跳转到详情页
          toCloudMarket(row){
            document.body.style.overflow = 'visible'
            this.$router.push({path:'/cloudMarket',query: {productId: row.objectId, orderType: 1}})
          }
        },
        mounted() {
            this.getResourceList()
        }
    }
</script>

<style lang="less" scoped>
.cursor-item {
    cursor: pointer;
}
    .page {
        margin: 20px 35px;
        width: 97%;

        .title {
            color: #F56E65;
            font-size: 24px;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }

        .pane-container {
            height: 34px;

            .pane {
                height: 16px;
                font-size: 16px;
                margin-right: 40px;
                color: rgba(65, 65, 65, 1);
                line-height: 35px;
            }

            .pane:active {
                color: #F56E65;
            }

            .pane:hover {
                color: #F56E65;
            }

            .pane-active {
                color: #F56E65;
            }

            .pane-first {
                width: 40px;
            }

            .pane-second {
                width: 80px;
            }

            .pane-third {
                width: 110px;
            }

            .pane-fourth {
                width: 200px;
            }

            .pane-select {
                font-size: 16px;
                width: 300px;
                font-weight: 300;
                color: #414141;
                margin-right: 15px;
            }

            .pane-search {
                font-size: 16px;
                font-weight: 300;
                color: #414141;
                width: 380px;

                /deep/ .el-input {
                    width: 217px;
                }

                .query-btn {
                    color: #fff;
                    background-color: #ed4014;
                    width: 60px;
                    height: 34px;
                    padding: 0px;
                    margin-left: 10px;
                }
            }


        }

        .table-container {
            margin-top: 20px;

            /deep/ .el-table .cell, .el-table--border .el-table__cell:first-child .cell {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            /deep/ .el-table th.el-table__cell {
                background-color: #f8f8f9;
            }

            /deep/ .el-table thead {
                color: #515A6E;
            }

            /deep/ .el-table td.el-table__cell, /deep/ .el-table th.el-table__cell.is-leaf {
                padding-left: 10px;
                padding-right: 10px;
            }

            /deep/ .table-col {
                text-align: center;
            }
        }

        /deep/ .ivu-page {
            float: right;
            margin-right: 143px;
        }
    }

</style>
